.bottom-actions{
    position: fixed;    
    bottom: 0px;    
 }
 .bottom-menu{
    position: fixed;    
    bottom: 70px; 
    width: 100%;
 }
 .bottom-menu-mob{
    position: fixed;    
    bottom: 22px; 
    width: 100%;
 }
 .bottom-float{
    position: fixed;    
    bottom: 120px; 
 }
 .savebtn {
	width: 260px;
	font-size: 14px;
	position: fixed;
    bottom: 15px;
 }
 .submit-btn-wrapper{
    padding: 10px;
    background:white;
    width: 100%;
    margin: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;  
}
.cpe-icon {
    color: #00D56E;
    cursor: pointer;
    float: left;
}
.ap-icon{
cursor: pointer;
width: 24px;
float: left;
}
.cardbutton{
    border: none;
    box-shadow: 0px 2px 6px 0px #afafaf;
    width: 100%;  
}
@media (max-width: 768px) and (min-width: 479px){
    .webonly{
        display: none;
    }
    .card-text { height: 402px; overflow-y: scroll; width: 100%; }
    .accesspointname{
        width: 100%;
        font-size: 14px;
    }
    .description{
        width: 100%;
    }
    .serial{
        width: 100%;
    }
    .dropzonemob:focus {
        background-color: #E5EEF7;
    }
    
.qricon{
    position: absolute;
    right: 5px;
    margin: 12px;
}
}
@media (max-width: 479px) {
    .webonly{
        display: none;
    }
    .card-text { height: 402px; overflow-y: scroll;}
    .accesspointname{
        width: 100%;
    }
    .description{
        width: 100%;
    }
    .serial{
        width: 100%;
    }
    .dropzonemob:focus {
        background-color: #E5EEF7;
    }

.qricon{
    position: absolute;
    right: 15px;
    margin: 12px;
}
}
@media (max-width: 2000px) and (min-width: 768px){ 
    .mobonly{
        display: none;
    }
    
}

.card-body-padded {
    overflow: hidden auto;
    padding-bottom: 50px;
}