@media (max-width: 768px) and (min-width: 479px){
    .webonly{
        display: none;
    } 
    .search{
        width: 100%;
    }
    .searchnew{
        width: 100%;
        background: #fff 0% 0% no-repeat padding-box;
        height: 40px;
        border-radius: 20px;
        opacity: 1;
        font-size: 14px;
        text-overflow: ellipsis;
        outline: none;
        padding: 0 30px 0 33px;
        box-sizing: border-box;
        border: 1px solid transparent;
        box-shadow: 0px 3px 8px #00000029;
    }
}
@media (max-width: 479px) {
    .webonly{
        display: none;
    }
    .search{
        width: 100%;
    }
    .searchnew{
        width: 100%;
        background: #fff 0% 0% no-repeat padding-box;
        height: 40px;
        border-radius: 20px;
        opacity: 1;
        font-size: 14px;
        text-overflow: ellipsis;
        outline: none;
        padding: 0 30px 0 33px;
        box-sizing: border-box;
        border: 1px solid transparent;
        box-shadow: 0px 3px 8px #00000029;
    }
}
@media (max-width: 2000px) and (min-width: 768px){ 
    .mobonly{
        display: none;
    }
    .searchborder {
        border-bottom: 0.5px solid #E3E3E3;
        opacity: 1;
        padding: 9px 0px;
    }
}
