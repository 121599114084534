.mobset{
    text-align: left;
font: 14px;
letter-spacing: 0.28px;
color: #AAAAAA;
text-transform: uppercase;
opacity: 1;
}

@media (max-width: 768px) and (min-width: 479px) {
    .webonly {
        display: none;
    }
    .settingsdash {
        height: calc(100vh - 115px);
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 42px;
    }
    .settingbtn {
        width: 100%;
    }
}

@media (max-width: 479px) {
    .webonly {
        display: none;
    }
    .settingsdash {
        height: calc(100vh - 115px);
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 42px;
    }
    .settingbtn {
        width: 100%;
    }
 
}

@media (max-width: 2000px) and (min-width: 768px) {
    .mobonly {
        display: none;
    }
}