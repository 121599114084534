@media (max-width: 768px) and (min-width: 479px){
    .webonly{
        display: none;
    }
    .header-newlg{
        width: 120px;
        padding: 12px 5px;

    }
    .tabhash{
        justify-content: center;
        height: 40px;
    }
}
@media (max-width: 479px) {
    .webonly{
        display: none;
    }
    .header-newlg{
        width: 120px;
        padding: 12px 5px;
    }
    .tabhash{
        justify-content: center;
        height: 40px;
    }
}
@media (max-width: 2000px) and (min-width: 768px){ 
    .mobonly{
        display: none;
    }
}
@media (max-width: 336px){
    .webonly{
        display: none;
    }
    .header-newlg{
        width: 110px;
        padding: 12px 5px;
    }
    .tabhash{
        justify-content: center;
        height: 40px;
    }
    .headerlg{
        width: 50px;
        }
        .headerlgname{
        width: 70px;
        }
        .nav-tabs .nav-link {  
        font-size: 12px;
        }
}