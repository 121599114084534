.screenshotcard{
     background: #f4f6f7 0% 0% no-repeat padding-box;
   box-shadow: none;
   border-radius: 8px;
   opacity: 1;
   margin: 0px;
   outline: none;
   border: 1px solid transparent;
   position: relative;
   width: 100%;
   }

@media (max-width: 768px) and (min-width: 479px) {
    .webonly {
        display: none;
    }
    .param-info-mob {
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
        outline: none;
        border: 0px solid transparent;
        z-index: 1050;
    }
    .secdrop {
        width: 100%;
        margin: 0px;
     
    }
    .paramtext {
        width: 100%;
        margin: 0px;
    }
    .buildinfo{
        width: 100%;
    }
    .backicon-mob {
        position: absolute;
        padding: 0px 12px 10px 15px;
        cursor: pointer;
        width: 52px;
        left: 0px;
    }
}

@media (max-width: 479px) {
    .webonly {
        display: none;
    }
    .param-info-mob {
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
        outline: none;
        border: 0px solid transparent;
        z-index: 1050;
    }
    .secdrop {
        width: 100%;
        margin: 0px;
     
    }
    .paramtext {
        width: 100%;
        margin: 0px;
    }
    .buildinfo{
        width: 100%;
    }
    .backicon-mob {
        position: absolute;
        padding: 0px 12px 10px 15px;
        cursor: pointer;
        width: 52px;
        left: 0px;
    }
}

@media (max-width: 2000px) and (min-width: 768px) {
    .mobonly {
        display: none;
    }
}


/* Access point details Edit css */

@media (max-width: 768px){
.apdash{
    background: #F9FAFC;
    height: 100vh;
    padding: 10px;
    }
.apeditcard{
    width: calc(100vw - 20px);
    height: calc(100vh - 64px);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000029;
    border-radius: 8px;
    opacity: 1;
    margin: 0px;
    outline: none;
    border: 1px solid transparent;
    position: absolute;
    }
    .notes {
        width: 100%
    }
    .remove{
    position: absolute;
    font-size: 14px;
    color: #e22b2b;
    padding-left: 5px;
    cursor: pointer;
    }
    .removeclose{
    font-size: 20px;
    color: #e22b2b;
    cursor: pointer;
    }
    .cardbtn{
        width: 100%;
    }
    .deletebtn{
        width: 100%;
    }
    .deleteicon{
        left: 63px
    }
}

/* network status speed test */

@media (max-width: 768px){
    .apdash{
        background: #F9FAFC;
        height: 100vh;
        padding: 10px;
        }
    .apeditcard{
        width: calc(100vw - 20px);
        height: calc(100vh - 64px);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 8px #00000029;
        border-radius: 8px;
        opacity: 1;
        margin: 0px;
        outline: none;
        border: 1px solid transparent;
        position: absolute;
        }
        .speedtestcard{
         height:115px;
        width: calc(100vw - 20px);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 8px #00000029;
        border-radius: 8px;
        opacity: 1;
        margin: 0px;
        outline: none;
        border: 1px solid transparent;
        position: relative;
        }
        .speedtest a{
            text-decoration: none;
            color: #fff;
        }
        .retest{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: #eef3f7;
            padding: 10px;
        }
        .retesticon{
            font-size: 45px;
            color: #0073FF;
        }
    }