

@media (max-width: 768px){
.apdash{
    background: #F9FAFC;
    height: 100vh;
    padding: 10px;
    }
    .notidash{
        background: #F9FAFC;
        height: calc(100vh - 30px);
        padding: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        }   
.profilecard{
    width: calc(100vw - 20px);
    height: calc(100vh - 134px);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000029;
    border-radius: 8px;
    opacity: 1;
    margin: 0px;
    outline: none;
    border: 1px solid transparent;
    position: absolute;
    }
    .noticard{
        width: calc(100vw - 20px);
        height: 60px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 8px #00000029;
        border-radius: 8px;
        opacity: 1;
        margin: 0px;
        outline: none;
        border: 1px solid transparent;
        position: absolute;
        margin-bottom: 10px;
        }
    .buildinfocard{
        width: calc(100vw - 20px);
        height: calc(100vh - 134px);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 5px -2px #00000029;
        border-radius: 8px;
        opacity: 1;
        margin: 0px;
        outline: none;
        border: 1px solid transparent;
        position: absolute;
        }
    .cardbtn{
        width: 100%;
    }
    .param-info-mob {
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
        outline: none;
        border: 0px solid transparent;
        z-index: 1050;
        overflow: hidden;
    }
    .eyeicon{
        position: absolute;
        right: 0px;
        margin: 20px;
        color: #d3d3d3;
        font-size: 18px;
        z-index: 999;
    }
    .backicon-mob {
        position: absolute;
        padding: 3px 12px 10px 15px;
        cursor: pointer;
        width: 52px;
        left: 0px;
    }
    .account-settings{
        font-size: 20px;
    }
}
