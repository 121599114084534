@media (max-width: 768px) and (min-width: 479px) {
  .webonly {
    display: none;
  }
  .apinfocard {
    width: 100%;
    height: inherit;
    margin: 0px;
  }
  .apinfobody {
    padding: 10px;
  }
  .apimage {
    height: 144px;
    width: 97px;
  }
  .aptoggle {
    width: 40px;
    height: 40px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
    margin: 10px 20px 0px 0px;
    padding: 8px;
    color: #000;
    display: inline-block;
  }
  .dot {
    height: 4px;
    width: 4px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 3px;
    margin-left: 4px;
  }
  .apedit {
    width: 40px;
    height: 40px;
    background: #0073ff19 0% 0% no-repeat padding-box;
    border: 1px solid #3d91fa;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
    margin: 10px 10px 0px 0px;
    padding: 9px;
    color: "#2571BE";
    display: inline-block;
    text-align: center;
  }
  .apdel {
    width: 40px;
    height: 40px;
    background: #e22b2b1a 0% 0% no-repeat padding-box;
    border: 1px solid #e22b2b;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
    margin: 10px 10px 0px 0px;
    padding: 9px;
    color: #e22b2b;
    display: inline-block;
    text-align: center;
  }
}

@media (max-width: 479px) {
  .webonly {
    display: none;
  }
  .apinfocard {
    width: 100%;
    height: inherit;
    margin: 0px;
  }
  .apinfobody {
    padding: 10px;
  }
  .apimage {
    height: 144px;
    width: 97px;
  }
  .aptoggle {
    width: 40px;
    height: 40px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
    margin: 10px 15px 0px 0px;
    padding: 8px;
    color: #000;
    display: inline-block;
    text-align: center;
  }
  .apedit {
    width: 40px;
    height: 40px;
    background: #0073ff19 0% 0% no-repeat padding-box;
    border: 1px solid #3d91fa;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
    margin: 10px 15px 0px 0px;
    padding: 9px;
    color: #3d91fa;
    display: inline-block;
    text-align: center;
  }
  .apdel {
    width: 40px;
    height: 40px;
    background: #e22b2b1a 0% 0% no-repeat padding-box;
    border: 1px solid #e22b2b;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
    margin: 10px 10px 0px 0px;
    padding: 9px;
    color: #e22b2b;
    display: inline-block;
    text-align: center;
  }
  .dot {
    height: 4px;
    width: 4px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 3px;
    margin-left: 4px;
  }
}

@media (max-width: 2000px) and (min-width: 768px) {
  .mobonly {
    display: none;
  }
}

.ap-dot {
  background: gray;
  padding: 0px;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin-top: 14px;
  margin-left: 6px;
}

.distance-text {
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  color: #9b9b9b;
  opacity: 1;
}

.header-padded {
  padding: 10px;
  padding-top: 40px;
}

.card-body-bottom-padded {
  overflow-y: auto;
  padding-bottom: 40px;
}