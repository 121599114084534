.apcreate{
    width: 40px;
    padding: 7px 2px 10px 12px;
    position: absolute;
    cursor: pointer;
    margin-left: 2px;
}
.dashoption{
    padding: 9px;
    color: #000;
}
.dashoptionbody{
    transform: translate3d(172px, 43px, 0px) !important;
    padding: 10px 15px;
}
.optionsave{
    width: 100% !important;
    height: 35px !important;
}
.dashbtn{
    right: 0px;
    text-align: -webkit-right;
    width: 100%;
}
.dashbtn-bottom {
    position: fixed;
    bottom: 95px;
}
.dashbtn-keyboard{
    position: fixed;
    bottom: 10px;
}
.searchclose{
    width: 40px;
    height: 40px;
    background: #f5f5f7;
    position: absolute;
    border-radius: 50%;
    right: 7px;
    margin: 0px;
}
.searchcardmob{
    width: calc(100vw - 16px);
    height: 30vh; 
    bottom: 22px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000029;
    opacity: 1;
    margin: 0px;
    outline: none;
    border: 1px solid transparent;
    position: absolute;
    z-index: -1;
    border-radius: 20px 20px 0px 0px;
}
.searchcardmob-keyboard{
    width: calc(100vw - 16px);
    height: 40vh; 
    bottom: 22px;
}
.searchdetails{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 22vh;
    padding: 0px 5px 5px 10px;
    text-align: -webkit-left;
}
.searchdetails-keyboard{
    max-height: 29vh;
}
.bottom-actions {
    position: fixed;
    bottom: 0px;
}

.bottom-menu {
    position: fixed;
    bottom: 70px;
    width: 100%;
}

.bottom-float {
    position: fixed;
    bottom: 120px;
}

.submit-btn-wrapper {
    padding: 10px;
    background: white;
    width: 100%;
    margin: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.cardbutton {
    border: 0.1px solid #d5d5d5;
}

.heaticon-selected {
    vertical-align: middle;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #E22B2B 0% 0% no-repeat padding-box;
    opacity: 1;
    display: grid;
    float: right;
    cursor: pointer;
    margin-top: 15px;
}

.apicon-web {
    margin-bottom: 2px;
    margin-right: 5px;
}

.search-web {
    position: absolute;
    top: 8px;
    margin-left: 8px;
    padding: 0px;
}
.search-mob {
    position: absolute;
    top: 8px;
    padding: 0px;
    filter: brightness(0.5);
}

@media (max-width: 768px) and (min-width: 479px) {
    .webonly {
        display: none;
    }
    .closenew{
        width: 40px;
        position: absolute;
        top: 0px;
        margin-right: 8px;
        padding: 0px;
        right: 2px;
    }
    .calcicon {
        position: absolute;
        padding: 7px 4px 9px 6px;
        cursor: pointer;
    }
    .dropzone:focus {
        background-color: #E5EEF7;
    }
}

@media (max-width: 479px) {
    .webonly {
        display: none;
    }
    .btn:hover {
        background: #0059B21A 0% 0% no-repeat padding-box;
        color: #0059B2;
    }
    .closenew{
        width: 40px;
        position: absolute;
        top: 0px;
        margin-right: 8px;
        padding: 0px;
        right: 2px;
    }
    .apcreate{
        width: 40px;
        padding: 7px 2px 10px 12px;
    }
    .calcicon {
        position: absolute;
        padding: 7px 4px 9px 6px;
        cursor: pointer;
    }
    .dropzone:focus {
        background-color: #E5EEF7;
    }
 }

@media (max-width: 2000px) and (min-width: 768px) {
    .mobonly {
        display: none;
    }
}

@media (max-width: 336px) {
    .webonly {
        display: none;
    }
    .cpeicon {
        width: 40px;
        padding: 9px 6px 10px 14px
    }
    .measureicon {
        padding: 15px 14px 10px 5px;
        width: 42px;
    }
    .closenew{
        width: 40px;
        position: absolute;
        top: 0px;
        margin-right: 8px;
        padding: 0px;
        right: 2px;
    }
    .calcicon {
        position: absolute;
        padding: 7px 4px 9px 6px;
        cursor: pointer;
    }
    .dropzone:focus {
        background-color: #E5EEF7;
    }
}

.bottom-menu-mob {
    position: fixed;    
    bottom: 22px; 
    width: 100%;
}

.bottom-azimuth-btns {
    bottom: 76px;
}

.bottom-azimuth-status {
    bottom: 0px;
    background: white;
    height: 75px;
    padding: 6px;
    font-size: 17px;
    color: #5b5e62;
}

.bottom-menu-0 {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.bottom-menu-41 {
    position: fixed;
    bottom: 41px;
    width: 100%;
}

.bottom-menu-row {
    position: fixed;
    bottom: 41px;
    width: 100%;
}

.bottom-menu-hide {
    display: none;
    position: fixed;    
    bottom: 41px; 
    width: 100%;
}

.bottom-status-row {
    position: fixed;
    bottom: 0px;
    background: white;
    height: 40px;
    padding: 6px;
    font-size: 17px;
    color: #5b5e62;
}

.bottom-status-center {
    position: fixed;
    bottom: 0px;
    text-align: center;
    background: white;
    height: 40px;
    padding: 6px;
    font-size: 17px;
    color: #5b5e62;
}

.bottom-status-hide {
    display: none;
}

.floating-icons {
    position: fixed;
    bottom: 135px;
    right: 0px;
}

.floating-icons-shown {
    position: fixed;
    bottom: 135px;
    right: 0px;
}

.floating-icons-hidden {
    position: fixed;
    bottom: 135px;
    right: 0px;
}

.floating-search-shown {
    position: fixed;
    bottom: 95px;
    right: 0px;
}

.floating-search-hidden {
    position: fixed;
    bottom: 95px;
    right: 0px;
}

.icon-relative {
    position: relative;
}

.icon-relative-margin {
    position: relative;
    margin-bottom: 10px;
}