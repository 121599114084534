.title-bar-mob {
    background-color: white;
    position: fixed;
    width: 100%;
    z-index: 1050;
}

.build-info-mob {
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000029;
    border-radius: 8px;
    opacity: 1;
    outline: none;
    border: 0px solid transparent;
    position: absolute;
    z-index: 1050;
}

.submit-btn-wrapper {
    padding: 10px;
    background: white;
    width: 100%;
    margin: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.stay-bottom-mob {
    position: fixed;
    bottom: 0px;
}

.building-infocard-mob {
    cursor: pointer;
    box-shadow: 0px 3px 6px 0px #b8b8b8;
    margin: 0px 5px 10px 5px;
    border-radius: 10px;
    padding: 10px;
}

.list-divider {
    margin: 15px 0px;
    border-bottom: 0.5px solid #E3E3E3;
}
.dropzone {
	flex: 1;
	display: flex;
	flex-direction: column;
	text-align: left;
	padding: 10px;
	border-width: 2px;
	border-radius: 6px;
	border-color: #eeeeee;
	border-style: dashed;
	background-color: #F6F6F8;
	color: #9B9B9B;
	outline: none;
	transition: border .24s ease-in-out;
	justify-content: center;
	cursor: pointer;
}

@media (max-width: 768px) and (min-width: 479px) {
    .webonly {
        display: none;
    }
    .title-bar-mob {
        background-color: white;
        position: fixed;
        width: 100%;
    }
    .indoordrop {
        width: 100%;
    }
    .buildtext {
        width: 100%;
    }
    .floor {
        width: 35px;
        height: 40px;
        background: #F5F5F7 0% 0% no-repeat padding-box;
        border: 0px;
        border-radius: 6px;
        opacity: 1;
        cursor: pointer;
        margin: 10px 5px 0px;
        padding: 10px 6px;
        color: #000;
    }
}

.notes {
    width: 100%;
    background: #FFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #CED7DF;
    border-radius: 0px;
    opacity: 1;
    font-size: 14px;
    margin: 0px;
}

@media (max-width: 479px) {
    .webonly {
        display: none;
    }
    .title-bar-mob {
        background-color: white;
        position: fixed;
        width: 100%;
    }
    .indoordrop {
        width: 100%;
    }
    .buildtext {
        width: 100%;
    }
    .floor {
        width: 35px;
        height: 35px;
        background: #F5F5F7 0% 0% no-repeat padding-box;
        border: 0.5px solid #d5d5d6 ;
        border-radius: 50%;
        opacity: 1;
        cursor: pointer;
        margin: 10px 5px 0px;
        padding:5px 6px 10px 6px;
        color: #000;
    }
    .floor2 {
        width: 35px;
        height: 40px;
        background: #F5F5F7 0% 0% no-repeat padding-box;
        border: 0px;
        border-radius: 6px;
        opacity: 1;
        cursor: pointer;
        margin: 10px 5px 0px;
        padding: 3px 6px 10px 6px;
        color: #000;
    }
}

@media (max-width: 2000px) and (min-width: 768px) {
    .mobonly {
        display: none;
    }
}

.floorbtn-active {
    background: #0059B21A 0% 0% no-repeat padding-box;
    color: #0059B2;
}