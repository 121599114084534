.diabled-pointer{
	cursor: not-allowed;
}
.calculations-disabled {
	width: 30px;
	height: 30px;
	background: #F5F5F5 0% 0% no-repeat padding-box;
	border-radius: 20px;
	opacity: 1;
	position: absolute;
	text-align: center;
    cursor: not-allowed;
    right: 0px;
    bottom: 10px;
    padding: 3px;
}
.floatcalc{
    width: 40px;
    height: 40px;
    background-color: #f7a868;
    border-radius: 50%;
    position: absolute;
    right: -1px;
    border: none;
    box-shadow: none;
    cursor: pointer;
}
@media (max-width: 768px) and (min-width: 479px){
    .webonly{
        display: none;
    }
    .text-user{
        width: 100%;
        height: 48px;
    }
    .apcard{
        width: 100%;
        height: calc(100vh - 60vh);
        margin: 0px;
        bottom: 0px;
    }
    .calculations {
        width: 30px;
        height: 30px;
        background: #F5F5F5 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        right: 15px;
        bottom: -3px;
        text-align: center;
        padding: 3px;
        cursor: pointer;
    }
    .calculations-disabled {
        width: 30px;
        height: 30px;
        background: #F5F5F5 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        text-align: center;
        cursor: not-allowed;
        right: 15px;
        bottom: -3px;
        padding: 3px;
    }
    .card{
        position: relative;
    }
    .calccard {
        width: 100%;
        height: 43px;
        background: #f4f5f7 0% 0% no-repeat padding-box;
        border-radius: 25px;
        opacity: 1;
        margin: 0px;
    }
    .calccardact{
        width: 100%;
        height: 43px;
        background: #e5fbf0 0% 0% no-repeat padding-box;
        box-shadow: none;
        border-radius: 25px;
        opacity: 1;
        margin: 0px;
    }
}
@media (max-width: 479px) {
    .webonly{
        display: none;
    }
    .text-user{
        width: 100%;
        height: 48px;
    }
    .apcard{
        width: 100%;
        height: calc(100vh - 45vh);
        margin: 0px;
        bottom: 0px;
    }
    .calculations {
        width: 30px;
        height: 30px;
        background: #F5F5F5 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        bottom: -3px;
        right: 15px;
        text-align: center;
        padding: 3px;
        cursor: pointer;
    }
    .calculations-disabled {
        width: 30px;
        height: 30px;
        background: #F5F5F5 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        text-align: center;
        cursor: not-allowed;
        right: 15px;
        bottom: -3px;
        padding: 3px;
    }
    .card{
        position: relative;
    }
    .calccard {
        width: 100%;
        height: 43px;
        background: #f4f5f7 0% 0% no-repeat padding-box;
        border-radius: 25px;
        opacity: 1;
        margin: 0px;
    }
    .calccardact{
        width: 100%;
        height: 43px;
        background: #e5fbf0 0% 0% no-repeat padding-box;
        box-shadow: none;
        border-radius: 25px;
        opacity: 1;
        margin: 0px;
    }
}
@media (max-width: 2000px) and (min-width: 768px){ 
    .mobonly{
        display: none;
    }
}
