

@media (max-width: 768px) and (min-width: 479px) {
    .webonly {
        display: none;
    }
    .measuremob{
        height: 50px;
        bottom: 0px;
        position: fixed;
        width: 100%;
        margin: 0px;
        border-radius: 6px 6px 0px 0px;
    }
    .measureiconmob {
        position: absolute;
        padding: 12px 10px 15px 14px;
        cursor: pointer;
        width: 45px;
    }
    .apicon {
        width: 43px;
        padding: 7px 2px 10px 12px;
    }
}

@media (max-width: 479px) {
    .webonly {
        display: none;
    }
    .measuremob{
        height: 50px;
        bottom: 0px;
        position: fixed;
        width: 100%;
        margin: 0px;
        border-radius: 6px 6px 0px 0px;
    }
    .measure{
        color: #acacac;
    }
    .measure:active{
        color: #f59343;
    }
    .measureiconmob {
        position: absolute;
        padding: 12px 12px 15px 12px;
        cursor: pointer;
        width: 45px;
    }
}

@media (max-width: 2000px) and (min-width: 768px) {
    .mobonly {
        display: none;
    }
    .measure{
        color: #acacac;
    }
    .measure:active{
        color: #f59343;
    }
}

