.build-cardbody{
    padding: 0px;
    overflow: hidden;
}
@media (max-width: 768px) and (min-width: 479px){
    .webonly{
        display: none;
    }
}
@media (max-width: 479px) {
    .webonly{
        display: none;
    }
}
@media (max-width: 2000px) and (min-width: 768px){ 
    .mobonly{
        display: none;
    }
}
@media (max-width: 336px){
    .webonly{
        display: none;
    }
}