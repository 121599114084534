* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
}

.rma-history-button {
  cursor: pointer;
  margin-left: 20px;
  background-color: rgb(255, 255, 255);
  border: 1.5px solid rgb(37, 89, 173);
  border-radius: 8px;
  padding: 0px 10px;
  text-align: center;
}

.rma-history-button-text {
  cursor: pointer;
  line-height: 0px;
  padding: 5px 0px;
}

body {
  font-family: Roboto, sans-serif;
}

a {
  color: #8f9fac;
}

.b-0 {
  border: 0px;
}

.b-none {
  border: none;
}
.p5 {
  padding: 5px !important;
}
.p-r-0 {
  padding-right: 0px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-r-16 {
  padding-right: 16px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-r-8 {
  padding-right: 8px;
}

.p-r-30 {
  padding-right: 30px;
}
.p-r-40 {
  padding-right: 40px;
}
.p-l-0 {
  padding-left: 0px;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-l-8 {
  padding-left: 8px;
}

.p-l-10 {
  padding-left: 10px;
}
.p-l-16 {
  padding-left: 16px;
}
.p-l-15 {
  padding-left: 15px;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-l-30 {
  padding-left: 30px;
}

.m-0 {
  margin: 0;
}

.m-l-5 {
  margin-left: 5px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-r-26 {
  margin-right: 26px;
}

.m-r-24 {
  margin-right: 24px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-13 {
  margin-right: 13px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-30 {
  margin-right: 30px;
}

.mt10 {
  margin-top: 10px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-6 {
  margin-top: 6px;
}
.m-t-7 {
  margin-top: 7px;
}

.m-r-m {
  margin-left: -7px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-9 {
  margin-top: 9px;
}
.m-t-10 {
  margin-top: 10px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-t-14 {
  margin-top: 14px;
}
.m-t-16 {
  margin-top: 16px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px;
}
.m-t-24 {
  margin-top: 24px;
}

.m-t-25 {
  margin-top: 25px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-t-60 {
  margin-top: 60px;
}
.m-t-29 {
  margin-top: 29px;
}
.m-t-30 {
  margin-top: 30px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-b-29 {
  margin-bottom: 29px;
}
.p-t-58 {
  padding-top: 58px;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.b-t-0 {
  border-top: 0px !important;
}

.b-r-0 {
  border-right: 0px !important;
}

.b-l-0 {
  border-left: 0px !important;
}

.b-b-0 {
  border-bottom: 0px !important;
}

.m-l-0 {
  margin-left: 0px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.font12 {
  font-size: 12px;
}

.font13 {
  font-size: 13px;
}

.font14 {
  font-size: 14px;
}

.font15 {
  font-size: 15px;
}

.font16 {
  font-size: 16px;
}
.font17 {
  font-size: 17px !important;
}
.font18 {
  font-size: 18px !important;
}
.font19 {
  font-size: 19px;
}
.font20 {
  font-size: 20px;
}
.font21 {
  font-size: 21px;
}
.font22 {
  font-size: 22px;
}
.font25 {
  font-size: 25px;
}
.fontw500 {
  font-weight: 500 !important;
}
.fontw400 {
  font-weight: 400 !important;
}
.h23 {
  height: 23px;
}
.h18 {
  height: 18px;
}
.h15 {
  height: 15px;
}
.w3 {
  width: 3%;
}
.w4 {
  width: 4%;
}
.w5 {
  width: 5%;
}
.w7 {
  width: 7%;
}
.w6 {
  width: 6%;
}

.w8 {
  width: 8%;
}
.w9 {
  width: 9%;
}
.w10 {
  width: 10%;
}
.w11 {
  width: 11%;
}
.w12 {
  width: 12%;
}
.w13 {
  width: 13%;
}
.w14 {
  width: 14%;
}
.w15 {
  width: 15%;
}
.w16 {
  width: 16%;
}

.w17 {
  width: 17%;
}

.w18 {
  width: 18%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w33 {
  width: 33.1%;
}
.w50 {
  width: 49.5%;
}

.center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buildingellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 30px !important;
}
.buildinglistellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}
.ellipsitooltip {
  max-width: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.networkellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip3 {
  width: 3%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip4 {
  width: 4%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip5 {
  width: 5%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip6 {
  width: 6%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.ellipsistooltip7 {
  width: 7%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.ellipsistooltip8 {
  width: 8%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip9 {
  width: 9%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip10 {
  width: 10%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip11 {
  width: 11%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip12 {
  width: 12%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip13 {
  width: 13%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip14 {
  width: 14%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsistooltip15 {
  width: 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cursor {
  cursor: pointer;
}
.paramrightborder {
  border-right: 0.5px solid #8f9fac !important;
}

.borderRadtop {
  border-radius: 6px 6px 0px 0px !important;
}
.borderRadnone {
  border-radius: 0px !important;
}
.borderRad1 {
  border-radius: 0px 0px 6px 0px !important;
}
.borderRadbottom {
  border-radius: 0px 0px 6px 6px;
}
input[type="textarea"][disabled] {
  background-color: #f9f9fc !important;
}

.native-textarea.sc-ion-textarea-md {
  padding: 10px 14px !important;
}

.ellipsistooltip90 {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsistooltip30 {
  width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control:focus {
  color: #9b9b9b;
  background-color: #fff;
  border-color: #ced7df;
  outline: none;
  box-shadow: none;
}

.mobsettings label.custom-control-label::before,
.mobsettings label.custom-control-label::after {
  right: -20px;
  left: auto;
}

.mobsettings .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFW…jONTZ9pgD0Q1/059QjEePTCrSdVnQi6p4/4LmLeqvrPwIMAHPr8wGO4asfAAAAAElFTkSuQmCC);
  width: 32px;
  height: 31px;
  left: auto !important;
  top: -8px;
  right: -28px;
}

.btn-img {
  height: 10px;
  margin-right: 10px;
}

.btn-after-submit {
  background: #f5f5f7;
  color: #000;
}
.homesetting {
  width: 30px;
  top: 0px;
  position: fixed;
  right: 0px;
  margin: 15px;
}
.icon-bg {
  font-size: 14px;
  text-align: right;
  position: absolute;
  color: #0059b2ad;
  right: 15px;
  width: 30px;
  height: 30px;
  background: #0059b20d 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #0073ff !important;
}

.MuiTypography-body1 {
  font-size: 14px !important;
}

.MuiTypography-root {
  color: #7c7c7c !important;
}

.MuiRadio-colorPrimary.Mui-checked ~ .MuiTypography-root {
  color: #0073ff !important;
}

.MuiFormControlLabel-root {
  margin-bottom: 0px !important;
}

[type="checkbox"]:not(:checked) + label:before {
  border: 1px solid #ededf0 !important;
  background: #ededf0;
}
.custom-control-input[type="checkbox"]:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.network [type="checkbox"]:not(:checked) + label:before {
  border: 1px solid #ededf0 !important;
  background: #fff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0059b21a;
}

.custom-control-label::before {
  top: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("./assets/img/checked-img.png");
  width: 32px;
  height: 31px;
  left: -32px;
  top: -8px;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 12px;
  background-origin: content-box;
  background-image: url("./assets/img/checked.png");
  color: #9b9b9b;
  font-size: 14px;
}

select.form-control-mob {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 12px;
  background-origin: content-box;
  background-image: url("./assets/img/checked.png");
  padding: inherit;
}

.form-label-group > label {
  top: 10px;
  left: 3px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  color: #9b9b9b;
  cursor: text;
}

.form-label-group > label.pages {
  top: 2px;
  left: 3px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  color: #9b9b9b;
  cursor: text;
}

.form-label-group > label.acpdetails {
  top: 2px;
  left: 3px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  color: #9b9b9b;
  cursor: text;
}
.form-label-group > label.importdetails {
  top: 2px;
  left: 3px;
  padding: 10px;
  text-align: left;
  font-size: 13px;
  color: #9b9b9b;
  cursor: text;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  font-size: 11px;
  color: #9b9b9b;
  padding: 0px 8px;
}

.form-label-group input:not(:placeholder-shown) ~ label.pages {
  font-size: 11px;
  color: #9b9b9b;
  padding: 2px 8px;
}

.form-label-group input:not(:placeholder-shown) ~ label.acpdetails {
  font-size: 11px;
  color: #9b9b9b;
  padding: 2px 8px;
}
.form-label-group input:not(:placeholder-shown) ~ label.importdetails {
  font-size: 10px;
  color: #9b9b9b;
  padding: 2px 8px;
}
.loading-css {
  background: #8a8a8a54;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2000;
}

.loading-container {
  position: absolute;
  top: 48%;
  left: 50%;
  margin-top: -50px;
  margin-left: -200px;
  width: 35%;
  height: 100px;
  background: white;
  border-radius: 10px;
}

.forgot-password {
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  box-shadow: 0px 0px 10px 0px #bfbfbf;
  margin: 15%;
  border-radius: 10px;
  padding: 30px;
}

.btn-tick-icon {
  color: green;
  margin-right: 10px;
}

.img {
  width: 120px;
}

@media (min-height: 500px) and (max-height: 671px) {
  .login-bg {
    background-image: url("./assets/img/background.png");
    overflow: hidden;
    height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .login-bghome {
    background-image: url("./assets/img/background.png");
    overflow: hidden;
    height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .bg-slogan {
    position: relative;
    top: 308px;
    left: 359px;
    width: 400px;
    height: 130px;
  }
}

@media (min-height: 672px) and (max-height: 768px) {
  .login-bg {
    background-image: url("./assets/img/background.png");
    overflow: hidden;
    height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: inherit;
    background-attachment: fixed;
    background-position: 0% 13%;
  }
  .login-bghome {
    background-image: url("./assets/img/background.png");
    overflow: hidden;
    height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: inherit;
    background-attachment: fixed;
    background-position: 0% 13%;
  }
  .bg-slogan {
    position: relative;
    top: calc(100vh - 460px);
    left: calc(100% - 433px);
  }
}

@media (min-height: 768px) and (max-height: 1000px) {
  .login-bg {
    background-image: url("./assets/img/background1.png");
    overflow: hidden;
    height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .login-bghome {
    background-image: url("./assets/img/background1.png");
    overflow: hidden;
    height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .bg-slogan {
    position: relative;
    top: 320px;
    left: 380px;
    width: 400px;
    height: 130px;
  }
}

@media (min-height: 1000px) and (max-height: 1200px) {
  .login-bg {
    background-image: url("./assets/img/background1.png");
    overflow: hidden;
    height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .login-bghome {
    background-image: url("./assets/img/background1.png");
    overflow: hidden;
    height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .bg-slogan {
    position: relative;
    top: 430px;
    left: 500px;
    width: 400px;
    height: 130px;
  }
}

@media (min-height: 1000px) and (max-height: 1500px) {
  .start {
    padding-top: 50%;
  }
}

@media (min-height: 800px) and (max-height: 1000px) {
  .start {
    padding-top: 40%;
  }
}

@media (max-height: 800px) and (min-height: 650px) {
  .start {
    padding-top: 30%;
  }
}

@media (max-height: 520px) {
  .logo {
    display: none;
  }
  .welcome {
    top: 80px;
    position: fixed;
  }
  .searchcardmob {
    height: 36vh;
  }
}
@media (max-height: 650px) {
  .signlogo {
    top: 12px !important;
  }
}

.app-body {
  background-color: #fff;
}

.bg-text {
  text-align: left;
  font-size: 29px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 0.6;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.login-logo {
  top: 100px;
  padding: 0px;
  width: 100%;
  height: 144px;
  text-align: center;
  opacity: 1;
  background-position: center;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-text {
  text-align: center;
  font-size: 22px;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font-weight: 500;
}

.login-texthome {
  top: 447px;
  left: 996px;
  text-align: center;
  font-size: 22px !important;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.modal-sm {
  max-width: 300px;
}

.btn {
  width: 268px;
  height: 48px;
  background: #0059b2 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0;
  color: #ffffff;
  font-family: Roboto, sans-serif;
}

.btn2 {
  width: 268px;
  height: 48px;
  background: #e5eef7 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0;
  color: #0159b2;
  font-family: Roboto, sans-serif;
}

.btn:focus {
  outline: none !important;
}

.lightbtn {
  width: 268px;
  height: 48px;
  border-radius: 6px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0;
  background: #0059b21a 0% 0% no-repeat padding-box;
  color: #0059b2;
}

.lightbtn:focus {
  outline: none;
}

.btn-log {
  width: 268px;
  height: 48px;
  background: #0059b2 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0;
  color: #ffffff;
}

.btn:disabled {
  background: #ededf0 0% 0% no-repeat padding-box;
  color: #9b9b9b;
}

.btn-submitted {
  background: #0059b21a 0% 0% no-repeat padding-box;
  color: #0059b2;
}

.btn-secondary-c3 {
  background: #0059b21a 0% 0% no-repeat padding-box;
  color: #0059b2;
}

.login-info {
  height: 31px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0;
  color: #353435;
  opacity: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-family: Roboto, sans-serif;
}

.cond {
  text-decoration: underline;
  cursor: pointer;
}

.app-settings-header {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 500;
}

.color-theme-blue {
  color: #0059b2;
}

.color-disabled {
  color: #d2d2d2;
}

.text-user {
  width: 268px;
  height: 59px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ced7df;
  border-bottom: 0.1px solid #ced7df;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  padding: 10px;
  font-size: 14px;
}

.text-box {
  border: 0.5px solid #8f9fac;
  border-radius: 6px;
  opacity: 1;
  padding: 10px;
}

input:-internal-autofill-selected {
  background-color: #fff !important;
}

.text-pwd {
  width: 268px;
  height: 59px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top: 0px;
  border: 1px solid #ced7df;
  border-radius: 0px 0px 6px 6px;
  opacity: 1;
  padding: 10px;
}

.text-pwd-border {
  width: 268px;
  height: 59px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ced7df;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  padding: 10px;
}

.checkbox {
  width: 268px;
}

.check {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0;
  color: #353435;
  opacity: 1;
}

.login-card {
  width: 268px;
  height: 119px;
  background: #eef3f7 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

.dash {
  height: calc(100vh - 58px);
  overflow: hidden;
}

.header {
  background: #ffffff 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(143, 159, 172, 1);
  -moz-box-shadow: 0px 0px 2px 0px rgba(143, 159, 172, 1);
  box-shadow: 0px 0px 2px 0px rgba(143, 159, 172, 1);
  opacity: 1;
}

.header-mob {
  background: #ffffff 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(143, 159, 172, 1);
  -moz-box-shadow: 0px 0px 2px 0px rgba(143, 159, 172, 1);
  box-shadow: 0px 2px 4px 0px #edeef3;
  opacity: 1;
}

.headerlg {
  width: 60px;
  padding: 7px 0px;
}

.header-logo {
  height: 26px;
  margin-top: 15px;
  margin-left: 22px;
}

.headerlgname {
  width: 80px;
  margin-top: 5px;
  margin-left: -12px;
}

.form-group {
  margin-bottom: 0.5rem;
}

.nav-tabs .nav-link {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #8f9fac;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  opacity: 1;
  cursor: pointer;
}

.nav-tabs .nav-link:hover {
  border-color: #fff;
  color: #0059b2;
}

.nav-tabs {
  border-bottom: 0px;
}

.tabhash {
  justify-content: center;
  height: 57px;
}

.nav-tabs .nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -5px;
}

.w-120 {
  width: 120px;
}

.tabhash .nav-item.show .nav-link,
.tabhash .nav-link.active {
  color: #0059b2;
  background-color: transparent;
  border-color: #fff #fff #0059b2;
  border-bottom: 4px solid #0059b2;
  border-radius: 3px 3px 0px 0px;
  opacity: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3px;
}

.avatar {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #edf0f2 0% 0% no-repeat padding-box;
  opacity: 1;
  float: right;
  margin: 0 15px;
}

.settings {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #edf0f2 0% 0% no-repeat padding-box;
  opacity: 1;
  display: grid;
  float: right;
  cursor: pointer;
}
.csettingscheck {
  position: relative !important;
  will-change: transform;
  top: 0px !important;
  left: 0px !important;
  transform: none !important;
  width: 20px !important;
  height: 20px !important;
}
.cogs {
  color: #8f9fac;
  font-size: 16px;
  margin: 7px;
}

.cogs:hover {
  color: #0059b2;
}
.cogs.active {
  color: #0059b2;
}
@media (max-width: 768px) and (min-width: 622px) {
  .headerlg {
    width: 55px;
  }
  .headerlgname {
    width: 75px;
  }
  .nav-tabs .nav-link {
    font-size: 14px;
  }
  .avatar {
    width: 30px;
    height: 30px;
  }
  .settings {
    width: 30px;
    height: 30px;
  }
  .cogs {
    font-size: 20px;
  }
}

@media (max-width: 622px) and (min-width: 576px) {
  .headerlg {
    width: 50px;
  }
  .headerlgname {
    width: 70px;
  }
  .nav-tabs .nav-link {
    font-size: 12px;
  }
  .avatar {
    width: 25px;
    height: 25px;
  }
  .settings {
    width: 25px;
    height: 25px;
  }
  .cogs {
    font-size: 18px;
  }
}

.card {
  width: 300px;
  height: 294px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000029;
  border-radius: 8px;
  opacity: 1;
  margin: 20px;
  outline: none;
  border: 1px solid transparent;
  position: absolute;
}

.searchicon {
  font-size: 14px;
  margin: 13px;
  position: absolute;
  background: transparent;
  color: #8f9fac;
  opacity: 1;
}

.search {
  width: 100%;
  height: 40px;
  background: #8f9fac1a 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  font-size: 14px;
  text-overflow: ellipsis;
  outline: none;
  padding: 0 30px 0 33px;
  box-sizing: border-box;
  border: 1px solid transparent;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9b9b9b;
  opacity: 1;
  font-size: 14px;
  font-family: Roboto, sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9b9b9b;
  font-size: 14px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9b9b9b;
  font-size: 14px;
}

.native-textarea.sc-ion-textarea-md::placeholder {
  color: #9b9b9b;
  opacity: 1;
  font-size: 14px;
}

.MuiSvgIcon-root {
  width: 0.9em !important;
  height: 0.9em !important;
}

a:active,
a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
  outline-style: none;
}

button:active,
button:focus {
  outline: 0;
  -moz-outline-style: none;
  outline-style: none;
}

.cardbutton-web {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #ced7df;
  border-radius: 20px;
  opacity: 1;
  width: 120px;
  height: 38px;
  cursor: pointer;
  letter-spacing: 0;
  color: #475057;
  font-size: 14px;
}

.cardbutton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #8f9fac;
  border-radius: 20px;
  opacity: 1;
  width: 120px;
  height: 40px;
  cursor: pointer;
  letter-spacing: 0;
  color: #475057;
  font-size: 14px;
  padding-left: 10px;
}

.cardbutton:focus {
  outline: none;
}

.cpeicon-web {
  color: #00d56e;
  padding: 4px;
  font-size: 12px;
}

.cpeicon {
  position: absolute;
  color: #00d56e;
  padding: 9px 3px 10px 18px;
  cursor: pointer;
}
.dasicon {
  position: absolute;
  color: #e22b2b;
  padding: 10px 3px 10px 18px;
  cursor: pointer;
}
.cardbtn {
  width: 268px;
  font-size: 14px;
}

.calcicon {
  position: absolute;
  padding: 7px 10px 10px 12px;
  cursor: pointer;
}

.calcicon-web {
  padding-right: 2px;
  margin-bottom: 2px;
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
}

.closeicon {
  position: absolute;
  right: 0;
  padding: 11px 25px 13px 0px;
  color: #9b9b9b;
  font-size: 16px;
  cursor: pointer;
}

.heaticon {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: grid;
  float: right;
  cursor: pointer;
  margin-top: 15px;
  box-shadow: 0px 2px 4px #0000001a;
}

.geoicon {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: grid;
  float: right;
  cursor: pointer;
  margin-top: 15px;
  box-shadow: 0px 2px 4px #0000001a;
}

.heaticon:active {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #e22b2b 0% 0% no-repeat padding-box;
  opacity: 1;
  display: grid;
  float: right;
  cursor: pointer;
  margin-top: 15px;
}

.invert-white {
  filter: brightness(0) invert(1);
}

.heat {
  background-image: url("./assets/img/heaticon.png");
  width: 60px;
  margin-left: -2px;
  position: absolute;
  padding: 2px 21px 10px 0px;
  cursor: pointer;
}

.heat2 {
  width: 32px;
  margin-left: 15px;
  margin-top: 5px;
  position: absolute;
  padding: 6px 11px 10px 0px;
  cursor: pointer;
}

.heat-mob {
  background-image: url("./assets/img/heaticon.png");
  width: 60px;
  margin-left: -2px;
  position: absolute;
  padding: 2px 21px 10px 0px;
  cursor: pointer;
}

.apicon {
  position: absolute;
  padding: 5px 10px 10px 12px;
  cursor: pointer;
  width: 52px;
  margin-left: 2px;
}

.measureicon {
  position: absolute;
  padding: 15px 15px 15px 10px;
  cursor: pointer;
  width: 48px;
}

.measureicon-web {
  margin-right: 2px;
  padding: 4px;
}

.apcard {
  height: calc(100vh - 85px);
}

.apcardbody {
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 160px);
}

.backbutton {
  width: 50px;
  height: 50px;
  color: rgb(0, 89, 178);
  background: #e5eef7 0% 0% no-repeat padding-box;
  border-radius: 8px 0px;
  opacity: 1;
  border: 1px solid transparent;
  margin: -1px;
  cursor: pointer;
}

.backbutton:focus {
  outline: none;
}

.backicon {
  position: absolute;
  padding: 18px 17px 10px 11px;
  cursor: pointer;
  width: 47px;
}

.backicon-mob {
  position: absolute;
  padding: 18px 17px 10px 11px;
  cursor: pointer;
  width: 45px;
}

.replacecbsd-header {
  margin: 5px 10px;
  font-size: 18px;
}

.apheader {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 500;
}

.apnote {
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  color: #475057;
  opacity: 1;
}

.apborder {
  border-bottom: 0.5px solid #d8d8d8fa;
  opacity: 1;
  padding: 10px 0px;
}

.accesspointname {
  width: 100%;
  height: 44px;
  background: #fff 0% 0% no-repeat padding-box;
  border: 0.5px solid #ced7df;
  border-radius: 6px;
  opacity: 1;
  padding: 10px;
  font-size: 14px;
}

.description {
  width: 100%;
  background: #fff 0% 0% no-repeat padding-box;
  border: 0.5px solid #ced7df;
  border-radius: 6px;
  opacity: 1;
  font-size: 14px;
}

.addimage {
  width: 268px;
  height: 44px;
  background: #f9f9fc 0% 0% no-repeat padding-box;
  border: 0.5px dashed #cccccc;
  border-radius: 6px;
  opacity: 1;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.cardtext {
  color: #8f9fac;
  opacity: 1;
  font-size: 14px;
}

.card-body {
  padding: 1rem;
}

.serial {
  width: 100%;
  background: #fff 0% 0% no-repeat padding-box;
  border: 0.5px solid #ced7df;
  border-radius: 6px;
  opacity: 1;
  padding: 10px;
  font-size: 14px;
}

.mapmarker {
  font-size: 24px;
  color: #eef3f7;
  -webkit-text-stroke-color: #0073ff;
  -webkit-text-stroke-width: 1.5px;
  cursor: pointer;
}

.mapmarker:active,
.mapmarker-selected {
  font-size: 25px;
  color: #0073ff;
  -webkit-text-stroke-color: #ffffff;
  -webkit-text-stroke-width: 1.5px;
  text-shadow: 0px 3px 3px #00000029;
  cursor: pointer;
}

.savebtn {
  width: 248px;
  font-size: 14px;
  position: fixed;
  bottom: 15px;
}

@media (max-height: 700px) and (min-height: 550px) {
  .apheader {
    font-size: 14px;
  }
  .apnote {
    font-size: 14px;
  }
  .accesspointname {
    height: 40px;
    font-size: 12px;
  }
  .description {
    font-size: 12px;
  }
  .addimage {
    height: 40px;
    font-size: 12px;
  }
  .cardtext {
    font-size: 13px;
  }
  .serial {
    height: 40px;
    font-size: 12px;
  }
  .savebtn {
    width: 248px;
    font-size: 14px;
    position: fixed;
    bottom: 30px;
  }
  ::placeholder {
    font-size: 14px;
  }
  :-ms-input-placeholder {
    font-size: 14px;
  }
  ::-ms-input-placeholder {
    font-size: 14px;
  }
  .form-label-group > label.pages {
    top: 0px;
    left: 3px;
  }
  .form-label-group input:not(:placeholder-shown) ~ label.pages {
    padding: 4px 8px;
  }
  .form-label-group > label.acpdetails {
    top: 2px;
    left: 3px;
  }
  .form-label-group input:not(:placeholder-shown) ~ label.acpdetails {
    padding: 4px 8px;
  }
}

.building-mob {
  width: 100%;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #d5d4d5;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  padding: 10px;
  font-size: 14px;
}

.searchcard {
  height: calc(100vh - 85px);
}
.elevationcard {
  width: 900px;
  height: 200px;
  position: fixed;
  bottom: -10px;
}
.searchtab {
  justify-content: center;
}

.searchtab .nav-link {
  font-size: 14px;
}

.searchtab .nav-item.show .nav-link,
.searchtab .nav-link.active {
  color: #0059b2;
  background-color: transparent;
  border-color: transparent;
}

.searchcontent {
  font-size: 14px;
  font-weight: 400;
}

.searchcontent-mob {
  font-size: 14px;
  font-weight: 500;
}

.searchcontenticon {
  color: #d8d8d8;
  padding-top: 12px;
  padding-left: 5px;
}

.searchcontenticon-mob {
  color: #d8d8d8;
  padding-top: 6px;
  padding-left: 12px;
  font-size: 20px;
}
.searchapicon {
  transform: rotate(-39deg);
  width: 50px;
  padding: 2px 13px 5px 8px;
  margin-left: -2px;
  position: absolute;
  cursor: pointer;
}
.searchborder {
  border-bottom: 0.5px solid #e3e3e3;
  opacity: 1;
  padding: 5px 0px;
}

.measurecontent {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #9b9b9b;
  opacity: 1;
}

.measurecontent:active {
  color: #ff9445;
}

.distance {
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  color: #9b9b9b;
  opacity: 1;
  font-family: "Roboto", sans-serif;
}

.justify-col-center {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.measurecard-web {
  width: 258px;
  height: 66px;
  background: #fff4ec 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.measure-text {
  color: #ff9445;
  opacity: 1;
}

.measurecard {
  width: 248px;
  height: 66px;
  background: #fef4ec 0% 0% no-repeat padding-box;
  box-shadow: 1px 3px 4px #0000001a;
  border-radius: 8px;
  opacity: 1;
  margin: 0px;
}

.measuredist {
  font-size: 20px;
  letter-spacing: 0;
  color: #ff9445;
  opacity: 1;
}

.analyticscard {
  width: 650px;
  position: relative;
}

.calculations {
  width: 30px;
  height: 30px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  position: absolute;
  right: 0px;
  bottom: 10px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.calculations:active {
  background: #ff944533 0% 0% no-repeat padding-box;
  border: 1px dashed #ff9445;
  border-radius: 20px;
  opacity: 1;
}

.calculateicon {
  color: #ff9445;
  font-size: 20px;
}

.calccard {
  width: 100%;
  height: 52px;
  background: #f4f5f7 0% 0% no-repeat padding-box;
  box-shadow: none;
  border-radius: 8px;
  opacity: 1;
  margin: 0px;
  position: relative;
}

.calccard:active {
  background: #e5fbf0 0% 0% no-repeat padding-box;
}

.calctext {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0;
  color: #8f9fac;
  opacity: 1;
  display: block;
}

.calc {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0;
  color: #8f9fac;
  opacity: 1;
  display: block;
}

.calc:active {
  color: #008947;
  opacity: 1;
  font-weight: 500;
}

.arch {
  bottom: 48px;
  z-index: 99;
  width: 35%;
  height: 22px;
  position: absolute;
  left: 50%;
  margin-left: -17%;
}

.status-dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.center-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-list {
  margin: 5px 0px 5px 5px;
  padding: 0px 0px 5px 0px;
  border: 2px solid #ededef;
  border-radius: 8px;
  cursor: pointer;
}

.search-list:hover {
  background-color: #ededef;
}

.network-loader {
  border: 3px solid #fff; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.network-loader-end {
  border: 3px solid #fff; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

.network-search {
  position: fixed;
  width: 305px;
  background-color: #ffffff;
  z-index: 99999;
  left: 30px;
  max-height: calc(75vh);
  overflow: hidden scroll;
  margin: 10px 0px;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 3px 10px 0px #00000052;
}

.network-search-bar {
  transition: 0.1s;
  background-color: rgb(255, 255, 255);
  width: fit-content;
  border: 2px solid #ededef;
  border-radius: 8px;
  padding: 5px;
}

.network-search-bar:focus-within {
  transform: scale(1.01);
  box-shadow: 0px 3px 10px 0px #00000052;
  border: none;
}

.row-hover {
  transition: 0.1s;
  transform-origin: center;
}

.row-hover:hover {
  transform: scale(1.01);
  background-color: #ededef;
}

tr.row-hover td {
  border-top: 2px solid #ededef;
  border-bottom: 2px solid #ededef;
}

tr.row-hover td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 2px solid #ededef;
}
tr.row-hover td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 2px solid #ededef;
}

.apimage {
  opacity: 1;
  border: none;
  border-radius: 6px;
}

.dataimportimage {
  opacity: 1;
  border: none;
  border-radius: 6px;
}

.apdetails {
  width: 248px;
  height: 78px;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0px;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}

.apdetails-web {
  width: 248px;
  max-height: 45px;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0px;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  overflow-y: auto;
}

.apinfo {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font-weight: 400;
}

.editbtn {
  width: 268px;
  height: 48px;
  background: #0073ff19 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.deletebtn {
  width: 268px;
  height: 48px;
  background: #e22b2b1a 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.editicon {
  position: absolute;
  margin: 16px;
  left: 94px;
  font-size: 13px;
  color: #0073ff;
  cursor: pointer;
}

.deleteicon {
  position: absolute;
  margin: 16px;
  left: 76px;
  font-size: 13px;
  color: #e22b2b;
  cursor: pointer;
}

.imgclose {
  font-size: 14px;
  color: #fff;
  position: absolute;
  background: #00000029 0% 0% no-repeat padding-box;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.imgclose-file {
  font-size: 16px;
  color: #9b9b9b;
  background: white;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #ccc;
  float: right;
}
.dropzclose {
  position: absolute;
  right: 0px;
  margin: 10px;
  bottom: 0px;
}
.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}
.accesscard {
  width: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001c;
  opacity: 1;
  margin: -1px;
  border-radius: 0px;
  right: 16px;
  height: calc(100vh - 58px);
}

.aptab {
  justify-content: center;
}

.aptab .nav-link {
  font-size: 14px;
  font-weight: 500;
}

.aptab .nav-item.show .nav-link,
.aptab .nav-link.active {
  color: #0059b2;
  border-color: transparent;
  background: #0059b219 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  font-weight: 500;
}

.sectab {
  justify-content: center;
}

.sectab .nav-link {
  font-size: 14px;
  padding: 0.5rem 1.8rem;
  border-bottom: 3px solid #ededf0;
  border-radius: 2px;
  font-weight: 500;
}

.sectab .nav-link:hover {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: #ededf0;
}

.sectab .nav-item.show .nav-link,
.sectab .nav-link.active {
  color: #0059b2;
  border-color: transparent;
  background-color: transparent;
  opacity: 1;
  border-bottom: 3px solid #0059b2;
  border-radius: 2px;
  font-weight: 500;
}

.secdrop {
  width: 100%;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #8f9fac;
  border-radius: 6px;
  opacity: 1;
  margin: 0px 10px;
  text-align: left;
  padding: 10px;
  cursor: pointer;
}

.dropd {
  color: #8b8f91;
  float: right;
  cursor: pointer;
}

.modalclose {
  color: #00000073;
  font-size: 16px;
  opacity: 0.5;
  cursor: pointer;
  position: absolute;
  right: 15px;
}
.modalcloseicon {
  color: #00000073;
  font-size: 16px;
  opacity: 1;
  cursor: pointer;
  right: 15px;
}
.resetcloseicon {
  color: #00000073;
  font-size: 16px;
  opacity: 1;
  cursor: pointer;
  position: absolute;
  right: 15px;
}
.modaltext {
  font-size: 16px;
  letter-spacing: 0;
  color: #353435;
  opacity: 1;
}

.modalbtn {
  width: 132px;
  font-size: 14px;
}

.add {
  height: 33px;
  padding-top: 0px;
  padding-left: 9px;
  width: 16px;
}

.btn-circle {
  width: 33px;
  height: 33px;
  padding: 0px 0px;
  border-radius: 15px;
  font-size: 24px;
  text-align: center;
  background: #edf0f2 0% 0% no-repeat padding-box;
  margin-left: 14px;
  margin-top: 10px;
}

.buildinglist {
  border-bottom: 0.5px solid #e3e3e3;
  opacity: 1;
  padding: 0px 0px 0px 80px;
  padding-right: 10px;
}

.image {
  width: 81px;
  height: 101px;
  background: transparent url("./assets/img/ShopsatColumbus1.png") 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.container > p {
  font-size: 1rem;
}

.container > em {
  font-size: 0.8rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  border-width: 2px;
  border-radius: 6px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #f6f6f8;
  color: #9b9b9b;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 40px;
}

.dropzone-uploaded {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  border-width: 1px;
  border-radius: 6px;
  border-color: 1px solid #00d56e;
  border-style: double;
  background-color: #00d56e1a;
  color: #00d56e;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
}

.dropzone-mob {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  border-width: 2px;
  border-radius: 6px 0px 0px 6px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #f6f6f8;
  color: #9b9b9b;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  border-right: none;
}

.dropzone-uploaded-mob {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  border-width: 1px;
  border-radius: 6px 0px 0px 6px;
  border-color: 1px solid #00d56e;
  border-style: double;
  background-color: #00d56e1a;
  color: #00d56e;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  border-right: none;
}

.v-h-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-field-action {
  background: #f6f6f8;
  border-radius: 0px 6px 6px 0px;
  border: 2px dashed #eee;
  border-left: none;
  height: 100%;
}

.image-field-action-uploaded {
  background: #00d56e1a;
  border-radius: 0px 6px 6px 0px;
  border: 1px solid #00d56e;
  border-left: none;
  height: 100%;
}

.dropzone:focus {
  border-color: #0059b25e;
  color: #0059b2;
  background-color: #e5eef7;
}

.dropzone.disabled {
  opacity: 0.6;
}

.dropzone-set {
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.dropzone-setheight {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-width: 2px;
  border-radius: 6px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #f9f9fc;
  color: #9b9b9b;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 248px;
  text-align: center;
}

.dropzone-setheight-uploaded {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-width: 2px;
  border-radius: 6px;
  border-color: 1px solid #00d56e;
  border-style: double;
  background-color: #00d56e1a;
  color: #00d56e;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 248px;
  text-align: center;
}

.importdropz {
  flex: 1;
  display: block;
  flex-direction: column;
  padding: 10px;
  border-width: 2px;
  border-radius: 6px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #f9f9fc;
  color: #9b9b9b;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 160px;
  text-align: center;
}

.importdropzuploaded {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-width: 2px;
  border-radius: 6px;
  border-color: 1px solid #00d56e;
  border-style: double;
  background-color: #00d56e1a;
  color: #00d56e;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 160px;
  text-align: center;
}

.submitbtn:active {
  color: #000;
  background: #f5f5f7 0% 0% no-repeat padding-box;
}

.buildtext {
  width: 100%;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #8f9fac;
  border-top: 0.1px solid #8f9fac;
  border-radius: 0px;
  opacity: 1;
  text-align: left;
  padding: 10px;
}

.buildtext-mob {
  width: 100%;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #d5d4d5;
  border-top: 0.1px solid #8f9fac;
  border-radius: 0px;
  opacity: 1;
  text-align: left;
  padding: 10px;
}

.buildtext-web {
  width: 100%;
  height: 44px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #ced7df;
  border-bottom: none;
  border-radius: 0px;
  opacity: 1;
  text-align: left;
  padding: 10px;
}

.browse-set-floor {
  position: relative;
  top: 62px;
  left: 90px;
  color: #0059b2;
  font-size: 14px;
}

.paramtext-web {
  background: white;
  border: none;
  border-top: 0.5px solid #8f9fac;
  padding: 10px;
  width: 100%;
  height: 45px;
  font-size: 14px;
}

.paramtext-mob {
  background: white;
  border: none;
  border: 0.5px solid #d5d4d5;
  padding: 10px;
  width: 100%;
  height: 45px;
  font-size: 14px;
}
.paraminput-mob {
  background: white;
  border: none;
  border: 0.5px solid #d5d4d5;
  padding: 10px;
  width: 100%;
  height: 60px;
  font-size: 14px;
}
.dropdown-item:hover {
  text-decoration: none !important;
  background-color: inherit !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000;
}

#installationTab.installation {
  background: #eef3f7;
  border-radius: 6px 6px 0px 0px;
}

.accordion #installationTab.installation.collapsed span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d7";
  display: inline-block;
  vertical-align: middle;
  float: right;
}

.accordion #installationTab.installation span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d8";
  display: inline-block;
  vertical-align: middle;
  float: right;
}

.param-clearfields {
  position: absolute;
  right: 30px;
  font-size: 12px;
  letter-spacing: 0;
  color: #8f9fac;
  opacity: 1;
  cursor: pointer;
  margin: 14px;
}
#installationTab.installation span.clearfields {
  position: absolute;
  right: 46px;
  font-size: 12px;
  letter-spacing: 0;
  color: #8f9fac;
  opacity: 1;
  cursor: pointer;
  margin: 3px;
}

#installationTab.installation.collapsed {
  background-color: white !important;
  border-radius: 6px;
}

#installationTab.installation.collapsed span.clearfields {
  display: none;
}

#cbsdTab.cbsd {
  background: #eef3f7;
  border-radius: 6px 6px 0px 0px;
}

#cbsdTab.cbsd span.clearfields1 {
  position: absolute;
  right: 46px;
  font-size: 12px;
  letter-spacing: 0;
  color: #8f9fac;
  opacity: 1;
  cursor: pointer;
  margin: 3px;
}

#cbsdTab.cbsd.collapsed {
  background-color: white !important;
  border-radius: 6px;
}

#cbsdTab.cbsd.collapsed span.clearfields1 {
  display: none;
}

.accordion #cbsdTab.cbsd span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d8";
  display: inline-block;
  vertical-align: middle;
  float: right;
}

.accordion #cbsdTab.cbsd.collapsed span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d7";
  display: inline-block;
  vertical-align: middle;
  float: right;
}

#optionalTab.option {
  background: #eef3f7;
  border-radius: 6px 6px 0px 0px;
}

#optionalTab.option span.clearfields2 {
  position: absolute;
  right: 46px;
  font-size: 12px;
  letter-spacing: 0;
  color: #8f9fac;
  opacity: 1;
  cursor: pointer;
  margin: 3px;
}

#optionalTab.option.collapsed {
  background-color: white !important;
  border-radius: 6px;
}

#optionalTab.option.collapsed span.clearfields2 {
  display: none;
}

.accordion #optionalTab.option span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d8";
  display: inline-block;
  vertical-align: middle;
  float: right;
}

.accordion #optionalTab.option.collapsed span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d7";
  display: inline-block;
  vertical-align: middle;
  float: right;
}

/* Mob  accordion */

#installationTab.installationmob {
  background: #fff;
  border-radius: 6px 6px 0px 0px;
}

.accordion #installationTab.installationmob.collapsed span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d7";
  display: inline-block;
  vertical-align: middle;
  float: right;
  color: #8b8f91;
}

.accordion #installationTab.installationmob span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d8";
  display: inline-block;
  vertical-align: middle;
  float: right;
  color: #000;
}

#installationTab.installationmob span.clearfields {
  position: absolute;
  right: 46px;
  font-size: 12px;
  letter-spacing: 0;
  color: #8f9fac;
  opacity: 1;
  cursor: pointer;
  margin: 3px;
}

#installationTab.installationmob.collapsed {
  background-color: white !important;
  border-radius: 6px;
}

#installationTab.installationmob.collapsed span.clearfields {
  display: none;
}

#cbsdTab.cbsdmob {
  background: #fff;
  border-radius: 6px 6px 0px 0px;
}

#cbsdTab.cbsdmob span.clearfields1 {
  position: absolute;
  right: 46px;
  font-size: 12px;
  letter-spacing: 0;
  color: #8f9fac;
  opacity: 1;
  cursor: pointer;
  margin: 3px;
}

#cbsdTab.cbsdmob.collapsed {
  background-color: white !important;
  border-radius: 6px;
}

#cbsdTab.cbsdmob.collapsed span.clearfields1 {
  display: none;
}

.accordion #cbsdTab.cbsdmob span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d8";
  display: inline-block;
  vertical-align: middle;
  float: right;
  color: #000;
}

.accordion #cbsdTab.cbsdmob.collapsed span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d7";
  display: inline-block;
  vertical-align: middle;
  float: right;
  color: #8b8f91;
}

#optionalTab.optionmob {
  background: #fff;
  border-radius: 6px 6px 0px 0px;
}

#optionalTab.optionmob span.clearfields2 {
  position: absolute;
  right: 46px;
  font-size: 12px;
  letter-spacing: 0;
  color: #8f9fac;
  opacity: 1;
  cursor: pointer;
  margin: 3px;
}

#optionalTab.optionmob.collapsed {
  background-color: white !important;
  border-radius: 6px;
}

#optionalTab.optionmob.collapsed span.clearfields2 {
  display: none;
}

.accordion #optionalTab.optionmob span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d8";
  display: inline-block;
  vertical-align: middle;
  float: right;
  color: #000;
}

div.gm-style-mtc {
  right: 60px;
}

.accordion #optionalTab.optionmob.collapsed span.arrow:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0d7";
  display: inline-block;
  vertical-align: middle;
  float: right;
  color: #8b8f91;
}

.dropdown.techfilter img.filterarrow {
  transform: rotate(0deg);
}

.dropdown.techfilter span.filtername {
  color: #142443;
}

.dropdown.techfilter.show img.filterarrow {
  transform: rotate(180deg);
}

.dropdown.techfilter.show span.filtername {
  color: #0073ff;
}

.dropdown.installer img.filterarrow {
  transform: rotate(0deg);
}

.dropdown.installer span.filtername {
  color: #142443;
}

.dropdown.installer.show img.filterarrow {
  transform: rotate(180deg);
}

.dropdown.installer.show span.filtername {
  color: #0073ff;
}

.dropdown.techdrop img.filterarrow {
  transform: rotate(0deg);
}

.dropdown.techdrop span.filtername {
  color: #142443;
}

.dropdown.techdrop.show img.filterarrow {
  transform: rotate(180deg);
}

.dropdown.techdrop.show span.filtername {
  color: #0073ff;
}

.sitetable tbody tr.panel-group.analytics td img.arrow {
  transform: rotate(0deg);
}

.sitetable tbody tr.panel-group.analytics.collapsed td img.arrow {
  transform: rotate(180deg);
}

.sitetable tbody tr.panel-group.canalytics td img.arrow {
  transform: rotate(180deg);
}

.sitetable tbody tr.panel-group.canalytics td.collapsed img.arrow {
  transform: rotate(0deg);
}

.table tbody + tbody {
  border-top: none;
}

.pwdsuccess {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e6fbf1;
}

.pwdsuccesstick {
  position: absolute;
  font-size: 36px;
  padding: 5px;
  color: #65e6a7;
}

.paramtext {
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top: 0.1px solid #8f9fac;
  border-radius: 0px;
  opacity: 1;
  margin: 0px;
  text-align: left;
  padding: 10px;
  color: #9b9b9b;
}

.paramdropd {
  margin: 0px 11px;
  font-size: 12px;
  padding: 7px;
}

.chevrondown {
  position: absolute;
  margin: 18px;
  align-items: right;
  align-content: right;
  right: 20px;
  font-size: 13px;
  color: #707070;
}

.editpen {
  color: #0059b2;
  font-size: 14px;
  margin: 17px;
  cursor: pointer;
}

.buildcard {
  width: 100%;
  height: 250px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #ced7df;
  border-radius: 6px;
  opacity: 1;
}

.addfloor {
  width: 35px;
  height: 35px;
  background: #e5eef7 0% 0% no-repeat padding-box;
  border: 1px solid #0059b2;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
  margin: 10px 5px 0px;
}

.addfloor-mob {
  width: 40px;
  height: 40px;
  background: #00d56e19 0% 0% no-repeat padding-box;
  border: 1px solid #5ed66f;
  border-radius: 21px;
  opacity: 1;
  cursor: pointer;
  margin: 10px 5px 0px;
}

.addfloor-web {
  width: 40px;
  height: 40px;
  background: #e5eef7 0% 0% no-repeat padding-box;
  border: 1px solid #0059b2ad;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
  margin: 10px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0059b2ad;
}

.plusicon {
  font-size: 16px;
  margin: 9px;
  color: #0059b2ad;
}

.addbuild {
  width: 40px;
  height: 40px;
  background: #e5eef7 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
  margin: 9px;
}

.floor {
  width: 35px;
  height: 35px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
  margin: 10px 5px 0px;
  padding: 6px;
  color: #000;
}

.floor-web {
  width: 40px;
  height: 40px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
  margin: 10px 5px 0px 5px;
  padding: 6px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.floor:active {
  background: #00d56e1a 0% 0% no-repeat padding-box;
  border: 1px solid #00d56e;
  color: #00d56e;
}

.indoorbtn {
  width: 118px;
  font-size: 12px;
}

input[type="radio"] {
  width: 15px;
  height: 15px;
}

.selectbuild {
  color: #b1bdc9;
  padding-top: 8px;
  padding-right: 5px;
}

.notes {
  width: 268px;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #8f9fac;
  border-radius: 0px;
  opacity: 1;
  font-size: 14px;
  margin: 0px;
}

.buildinfo {
  width: 123px;
  border-radius: 0px;
  background: #fff;
  color: #000;
}

.buildinfo-web {
  width: 129px;
  border-radius: 0px;
  background: #fff;
  color: #000;
}

.indoordrop {
  width: 268px;
  height: 48px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  border: 0.5px solid #8f9fac;
  border-radius: 6px;
  opacity: 1;
  text-align: left;
  padding: 10px;
  cursor: pointer;
}

.indoordrop-web {
  width: 100%;
  height: 48px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  border: 0.5px solid #8f9fac;
  border-radius: 6px;
  opacity: 1;
  text-align: left;
  padding: 10px;
  cursor: pointer;
}

.orspan {
  text-align: center;
  color: #8f9fac;
  top: 11px;
  font-size: 12px;
}

.floorbtn {
  width: 76px;
  height: 28px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  color: #5b5e62;
  border-radius: 14px;
  opacity: 1;
  font-size: 12px;
}

.floorbtn:active {
  background: #0059b21a 0% 0% no-repeat padding-box;
  color: #0059b2;
}

.floorbtn:focus {
  outline: none;
}

nav li {
  display: inline-block;
  margin-left: -20px;
}

nav a {
  text-decoration: none;
  color: #8f9fac;
  background: #ededf0;
  position: relative;
  display: inline-block;
  margin: 0 22px;
  border-radius: 7px 18px 0 0;
  /* just to smooth the top edges */
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  height: 47px;
  padding: 0.9rem 1.2rem 0.5rem 0.7rem !important;
  border-top: 1px solid #d8d8d8;
}

nav a:hover {
  color: #8f9fac;
  text-decoration: none;
}

nav a:before,
nav a:after {
  content: " ";
  position: absolute;
  top: 0;
  width: 23px;
  height: 47px;
  background-color: inherit;
}

nav a:after {
  height: 47px !important;
  border-radius: 0 10px 0 0;
  transform: skew(33deg);
  right: -8px;
  /* play with this one to give the LI border ~2px extrusion */
  border-right: 1px solid #d8d8d8;
  z-index: 1;
  top: -1px;
  border-top: 1px solid #d8d8d8;
  /* overlap next element */
}

nav a:before {
  border-radius: 5px 0 0 0;
  transform: skew(0deg);
  left: -13px;
  border-left: 1px solid #d8d8d8;
  top: -1px;
  border-top: 1px solid #d8d8d8;
  /* play with this one to give the LI border ~2px extrusion */
}

/* LI ACTIVE  */

nav a:active {
  color: #0059b2;
  background: #fff;
  box-shadow: 0px 1px 4px #0000001a;
}

nav li.active a:before {
  z-index: 1;
  border: 1px solid #fff;
  /* overlap prev element */
}

nav li.active a:after {
  border-bottom: 1px solid #fff;
}

.nav a.active:before {
  z-index: 1;
  box-shadow: -3px -2px 1px -1px #0000000a;
}

.nav a.active:after {
  z-index: 1;
  box-shadow: 2px -1px 1px 0px #0000000a;
}

#dropdownMenuLink div.dropdown-menu.show:hover {
  background-color: transparent;
}

.dropdown-menu.csettings.show {
  top: 26px !important;
  left: -19px !important;
}
.dropdown-menu.indoorfloor.show {
  top: 25px !important;
  left: -17px !important;
}
.dropdown-menu.outdoorfloor.show {
  top: 18px !important;
  left: -122px !important;
}
.dropdown-menu.floormenu.show {
  top: 25px !important;
  left: -151px !important;
}

.tabimport .nav-item.show .nav-link,
.tabimport .nav-link.active {
  color: #0059b2;
  background: #fff;
  box-shadow: 0px -2px 3px -2px #0000001a;
  border: 1px solid #fff;
}

.table-bg-hdr-color {
  background: #ededf0 0% 0% no-repeat padding-box;
}

nav a.active:before,
nav a.active:after {
  border: 1px solid #fff;
}

.importcard {
  width: calc(100vw - 36px);
  height: calc(100vh - 185px);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 4px #0000001a;
  opacity: 1;
  margin: -1px 18px;
  border-radius: 0px 6px 6px 6px;
}
.networkcard {
  width: calc(100vw - 31px);
  height: calc(100vh - 87px);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 4px #0000001a;
  opacity: 1;
  border-radius: 6px;
}
.networkbtn {
  width: 100px;
  height: 30px;
  font-size: 12px;
  float: right;
}

.sortdown {
  width: 10px;
  position: absolute;
  transform: rotate(180deg);
  margin-left: 10px;
  margin-top: 3px;
}

.importchevron {
  font-size: 13px;
  color: #707070;
  padding-right: 5px;
}

.sitecollapse {
  width: 100%;
  height: 48px;
  background: #f3f3f5 0% 0% no-repeat padding-box;
  border-radius: 6px;
  letter-spacing: 0.24px;
  color: #000;
  opacity: 1;
  font-size: 12px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 0px;
}

.sitehead {
  width: 100%;
  height: 48px;
  background: #ededf0 0% 0% no-repeat padding-box;
  border-radius: 6px;
  letter-spacing: 0.24px;
  color: #475057;
  opacity: 1;
  font-size: 12px;
}

.sitetable {
  margin-bottom: 0px;
}

.sitehead th {
  border: 0px;
  border-bottom: 0px !important;
  font-size: 12px;
  letter-spacing: 0.28px;
  font-weight: 400;
}

.sitetable td {
  border-top: 0px;
  border-bottom: 1px solid #ededf0;
  font-size: 14px;
  color: #000;
  letter-spacing: 0.28px;
  opacity: 1;
  vertical-align: middle;
}
.subtable th {
  border-top: 0px;
  border-bottom: 1px solid #ededf0;
  letter-spacing: 0.24px;
  color: #000;
  opacity: 1;
  font-size: 12px;
  vertical-align: middle;
}
.subtable td {
  border-top: 0px;
  border-bottom: 1px solid #ededf0;
  font-size: 14px;
  color: #000;
  letter-spacing: 0.28px;
  opacity: 1;
  vertical-align: middle;
}
.sitetable tr {
  height: 50px;
}

.sitecollapse tr {
  height: 50px;
}

.lf {
  width: 100%;
  height: 174px;
  margin: 0px;
  position: relative;
  box-shadow: 0px 1px 4px #0000001a;
}

.rt {
  width: 100%;
  height: 174px;
  margin: 0px;
  position: relative;
  box-shadow: 0px 1px 4px #0000001a;
}

.bardes {
  color: #8698a7;
  font-size: 12px;
}

.blueprogress ion-progress-bar {
  --buffer-background: #ededf0;
  --progress-background: #0073ff;
  --progress-border-radius: 20px;
}

.bakcg ion-fab-button {
  --background: #ffffff;
}

.blueprogress progress {
  border-radius: 10px;
}

.orangeprogress ion-progress-bar {
  --buffer-background: #ededf0;
  --progress-background: #ff9445;
}

.greenprogress ion-progress-bar {
  --buffer-background: #ededf0;
  --progress-background: #00d56e;
}

.settingdropdown {
  width: 100%;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 6px;
  opacity: 1;
}

.settinginput {
  width: 100%;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 6px;
  opacity: 1;
  padding: 10px;
}

.settingbtn {
  width: 100%;
  height: 48px;
  background: #f2f2f5 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 6px;
  opacity: 1;
}

.settingbtn:active {
  background: #e5eef7 0% 0% no-repeat padding-box;
  border: 1px solid #0059b2;
}

.settingbtn-active {
  background: #e5eef7 0% 0% no-repeat padding-box;
  border: 1px solid #0059b2;
}

.settingavatar {
  width: 80px;
  height: 80px;
  background: #bfbfbf 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50px;
}

.settingcard {
  width: calc(100% - 10px);
  height: calc(100vh - 138px);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 6px;
  opacity: 1;
  margin: 0px;
}

.editavatar {
  color: #fff;
  font-size: 16px;
  margin: 32px;
  cursor: pointer;
  position: absolute;
}

.update {
  font-size: 14px;
  position: absolute;
  right: 15px;
  margin: 12px;
  color: #0059b2;
  cursor: pointer;
  font-weight: 300;
}

@media (max-width: 5000px) and (min-width: 1362px) {
  .logomob {
    display: none;
  }
  .commheight {
    height: 15px;
  }
  .sasheight {
    height: 23px;
  }
  .domainsetting {
    text-align: center;
    letter-spacing: 0;
    opacity: 1;
    font-size: 12px;
    left: -16px;
    color: #aaaaaa;
  }
}

@media (max-width: 1362px) and (min-width: 1000px) {
  .settingbtn {
    width: 124px;
  }
  .logomob {
    display: none;
  }
  .commheight {
    height: 14px;
  }
  .sasheight {
    height: 23px;
  }
}
@media (max-width: 1362px) and (min-width: 1280px) {
  .domainsetting {
    text-align: center;
    letter-spacing: 0;
    opacity: 1;
    font-size: 11px;
    left: -16px;
    color: #aaaaaa;
  }
}

@media (max-width: 1280px) and (min-width: 1200px) {
  .domainsetting {
    text-align: center;
    letter-spacing: 0;
    opacity: 1;
    font-size: 10px;
    left: -16px;
    color: #aaaaaa;
  }
}
@media (max-width: 1200px) and (min-width: 1100px) {
  .domainsetting {
    text-align: center;
    letter-spacing: 0;
    opacity: 1;
    font-size: 9px;
    left: -16px;
    color: #aaaaaa;
  }
}

@media (max-width: 1110px) and (min-width: 940px) {
  .settingbtn {
    width: 80px;
  }
  .logomob {
    display: none;
  }
  .commheight {
    height: 12px;
  }
  .sasheight {
    height: 19px;
  }
  .domainsetting {
    text-align: center;
    letter-spacing: 0;
    opacity: 1;
    font-size: 9px;
    left: -16px;
    color: #aaaaaa;
  }
}

@media (max-width: 940px) and (min-width: 768px) {
  .settingbtn {
    width: 60px;
  }
  .logomob {
    display: none;
  }
  .commheight {
    height: 10px;
  }
  .sasheight {
    height: 17px;
  }
  .domainsetting {
    text-align: center;
    letter-spacing: 0;
    opacity: 1;
    font-size: 9px;
    left: -16px;
    color: #aaaaaa;
  }
}

@media (max-width: 767px) and (min-width: 480px) {
  /* FOR TABLETS */
  .webonly {
    display: none;
  }
  .login-bg {
    display: none;
  }
  .login-texthome {
    text-align: center;
    font-size: 20px !important;
    letter-spacing: 0;
    color: #fff;
    opacity: 1;
  }
  .login-bghome {
    background-image: url("./assets/img/Background_Img-Login.jpg");
    overflow: hidden;
    position: absolute;
    height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 30%;
  }
  .bg-slogan {
    display: none;
  }
  .bg-text {
    display: none;
  }
  .homecontent {
    bottom: 25px;
    position: fixed;
  }
  .homelogo {
    top: 50px;
    position: fixed;
  }
  .logoweb {
    display: none;
  }
  .img {
    width: 100px;
  }
  .imgmob {
    width: 70px;
  }
  .namemob {
    width: 100px;
  }
  .btn {
    font-size: 16px;
  }
  .btn:disabled {
    background: #f9f9fc 0% 0% no-repeat padding-box;
    color: #8f9fac;
  }
  .login-logo {
    top: 50px;
  }
  .text-user {
    width: 100%;
    height: 48px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ced7df;
    border-bottom: 0.1px solid #ced7df;
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
    padding: 10px;
    font-size: 18px;
  }
  .accesspointname {
    font-size: 18px;
  }
  .description {
    font-size: 18px;
  }
  .paramtext-mob {
    font-size: 18px;
  }
  select.form-control {
    font-size: 16px;
  }
  .text-pwd {
    width: 100%;
    height: 48px;
  }
  .lightbtn {
    width: 268px;
    height: 48px;
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0;
    background: #fff 0% 0% no-repeat padding-box;
    color: #0059b2;
  }
  .lightbtnmob {
    width: 268px;
    height: 48px;
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0;
    background: #0059b21a 0% 0% no-repeat padding-box;
    color: #0059b2;
    font-family: Roboto, sans-serif;
  }
  .lightbtnmob:focus {
    outline: none;
  }
  .backicon {
    position: fixed;
    padding: 18px 17px 10px 11px;
    cursor: pointer;
    width: 47px;
    top: 0px;
    left: 4px;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0px;
  }
  .modal-sm {
    max-width: 100%;
  }
  .native-textarea.sc-ion-textarea-ios {
    padding: 15px;
  }
}

@media (max-width: 479px) {
  /* LARGE SCREENED MOBILES */
  .webonly {
    display: none;
  }
  .login-bg {
    display: none;
  }
  .login-texthome {
    text-align: center;
    font-size: 22px !important;
    letter-spacing: 0;
    color: #fff;
    opacity: 1;
  }
  .login-bghome {
    background-image: url("./assets/img/Background_Img-Login.jpg");
    overflow: hidden;
    position: absolute;
    height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 30%;
  }
  .bg-slogan {
    display: none;
  }
  .bg-text {
    display: none;
  }
  .homecontent {
    bottom: 24px;
    position: fixed;
  }
  .homelogo {
    top: 45px;
    position: fixed;
  }
  .logoweb {
    display: none;
  }
  .img {
    width: 100px;
  }
  .imgmob {
    width: 70px;
  }
  .namemob {
    width: 100px;
  }
  .btn {
    width: 100%;
    font-size: 16px;
    height: 49px;
  }
  .btn:hover {
    background: #0059b2 0% 0% no-repeat padding-box !important;
    color: #fff !important;
  }
  .btn-log {
    width: 100%;
    height: 49px;
  }
  .btn-log:hover {
    background: #0059b2 0% 0% no-repeat padding-box;
    color: #fff;
  }
  .btn:disabled {
    background: #ededf0 0% 0% no-repeat padding-box;
    color: #9b9b9b;
  }
  .login-logo {
    top: 45px;
  }
  .text-user {
    width: 100%;
    height: 48px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ced7df;
    border-bottom: 0.1px solid #ced7df;
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
    padding: 10px;
    font-size: 18px;
  }
  .accesspointname {
    font-size: 18px;
  }
  .description {
    font-size: 18px;
  }
  .paramtext-mob {
    font-size: 18px;
  }
  select.form-control {
    font-size: 16px;
  }
  .text-pwd {
    width: 100%;
    height: 48px;
  }
  .checkbox {
    width: 100%;
  }
  .login-card {
    width: 100%;
  }
  .lightbtn {
    width: 100%;
    height: 49px;
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0;
    background: #fff 0% 0% no-repeat padding-box;
    color: #0059b2;
  }
  .lightbtnmob {
    width: 100%;
    height: 49px;
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0;
    background: #0059b21a 0% 0% no-repeat padding-box;
    color: #0059b2;
  }
  .lightbtnmob:focus {
    outline: none;
  }
  .check {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0;
    color: #353435;
    opacity: 1;
  }
  .login-info {
    height: 31px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0;
    color: #353435;
    opacity: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto, sans-serif;
  }
  .login-text {
    top: 447px;
    left: 996px;
    width: 170px;
    height: 25px;
    text-align: center;
    font-size: 22px;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
    font-weight: 500;
  }
  .form-label-group > label {
    top: 4px;
    left: 2px;
    padding: 10px;
    text-align: left;
    font-size: 16px !important;
    color: #9b9b9b;
    cursor: text;
  }
  .form-label-group > label.forgot {
    top: 9px;
    left: 2px;
    padding: 10px;
    text-align: left;
    font-size: 16px !important;
    color: #9b9b9b;
    cursor: text;
  }
  .form-label-group input:not(:placeholder-shown) ~ label.pages {
    font-size: 11px !important;
  }
  .form-label-group input:not(:placeholder-shown) ~ label {
    font-size: 13px !important;
  }
  .backicon {
    position: fixed;
    padding: 18px 17px 10px 11px;
    cursor: pointer;
    width: 47px;
    top: 0px;
    left: 4px;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0px;
  }
  .modal-sm {
    max-width: 100%;
  }
  .native-textarea.sc-ion-textarea-ios {
    padding: 15px;
  }
}

@media (max-width: 479px) and (min-width: 400px) {
  .cardbutton {
    font-size: 17px;
  }
  .apicon {
    width: 43px;
    padding: 7px 4px 10px 10px;
  }
}
@media (max-width: 400px) and (min-width: 380px) {
  .cardbutton {
    font-size: 15px;
  }
  .apicon {
    width: 43px;
    padding: 7px 4px 10px 10px;
  }
}
@media (max-width: 380px) and (min-width: 340px) {
  .cardbutton {
    font-size: 13px;
  }
  .apicon {
    width: 42px;
    padding: 9px 4px 10px 10px;
  }
}
@media (max-width: 340px) {
  .cardbutton {
    font-size: 11px;
  }
  .apicon {
    width: 40px;
    padding: 9px 4px 10px 10px;
  }
}
.activitydetails {
  width: 140px;
  border: 0.5px solid #8f9fac;
  opacity: 1;
  display: inline-block;
  padding: 10px;
}
.sectordetails {
  width: 162px;
  display: inline-block;
}

.brd {
  border-left: thin;
}

.brd2 {
  border-top: thin;
}

.csetting-text {
  position: relative;
  font-size: 12px;
  margin: 3px 12px;
  color: #0059b2;
  cursor: pointer;
}

button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.lockicon {
  position: fixed;
  padding: 18px 17px 10px 11px;
  cursor: pointer;
  width: 47px;
  top: 0px;
}

.measuredot {
  bottom: 48px;
  position: absolute;
  z-index: 999;
  text-align: center;
}
.updbtn {
  width: 64px;
  height: 25px;
  float: right;
  font-size: 12px;
  padding: 3px;
}
.gm-style .gm-style-iw-c {
  top: -25px;
}
.gm-style .gm-style-iw-t::after {
  top: -25px;
}
.gm-ui-hover-effect {
  display: none !important;
}
.carousel-left {
  justify-content: left;
  align-items: flex-start;
  top: 25px;
  left: -5px;
  width: 0%;
}
.carousel-right {
  justify-content: flex-end;
  align-items: flex-start;
  top: 25px;
  right: -5px;
  width: 0%;
}
ion-fab-button.ion-color-light {
  --ion-color-base: var(--ion-color-light, #ffffff) !important;
  --ion-color-base-rgb: var(--ion-color-light-rgb, 244, 245, 248) !important;
  --ion-color-contrast: var(--ion-color-light-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-light-shade, #d7d8da) !important;
  --ion-color-tint: var(--ion-color-light-tint, #f5f6f9) !important;
}

ion-fab-button.ion-color-danger {
  --ion-color-base: var(--ion-color-light, #e22b2b) !important;
}
.native-textarea.sc-ion-textarea-md ::placeholder {
  font-size: 14px;
}

input[type="email"]:disabled {
  background: #ededf0;
}

input[type="password"]:disabled {
  background: #ededf0;
}

input[type="text"][disabled] {
  background-color: #ededf0 !important;
}
input[type="number"][disabled] {
  background-color: #ededf0 !important;
}

.infoBox img {
  display: none;
}

input[type="number"] {
  line-height: 30px;
}
input[type="number"]:focus::-webkit-inner-spin-button {
  color: #333;
  text-align: center;
  cursor: pointer;
  display: block;
  width: 15px;
  height: 15px;
  padding: 4px;
  right: -7px;
  bottom: 3px;
  position: relative;
  border-radius: 28px;
}

input[type="number"]:hover::-webkit-inner-spin-button {
  width: 15px;
  height: 15px;
  padding: 4px;
  right: -7px;
  bottom: 3px;
  position: relative;
  border-radius: 28px;
}

.floor-card-active {
  background-color: rgb(0, 115, 255);
}
.indoordlt {
  position: absolute;
  margin: 17px;
  left: 45px;
  font-size: 13px;
  color: #e22b2b;
  cursor: pointer;
}
.floordlt {
  font-size: 16px;
  color: #e22b2b;
  cursor: pointer;
  margin: 9px;
}
@media (max-width: 400px) {
  .indoordlttext {
    font-size: 14px !important;
  }
  .indooredittext {
    font-size: 14px !important;
  }
}
@media (max-width: 320px) {
  .indoordlttext {
    font-size: 11px !important;
  }
  .indooredittext {
    font-size: 11px !important;
  }
}
.setedit {
  color: #0073ff;
  cursor: pointer;
}
.netacthead {
  font-size: 18px;
  letter-spacing: 0.1px;
  text-align: left;
  line-height: 22px;
  font-family: "Google Sans", Arial, sans-serif;
  font-weight: 600;
  color: #3c4043;
}
.netactid {
  font-size: 15px;
  font-weight: 700;
  color: #3c4043;
  text-align: left;
  letter-spacing: 0;
  opacity: 1;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.netactdetail {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  color: #3c4043;
  opacity: 1;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  padding-left: 5px;
}

.userdetailhead {
  display: table;
  background: none;
}
.userdetailbody {
  display: block;
  overflow: hidden scroll;
  max-height: calc(100vh - 305px);
  width: calc(100% + 8px);
}
.eyeicon {
  position: absolute;
  right: 0px;
  margin: 20px;
  color: #d3d3d3;
  font-size: 18px;
}
.networktable th {
  font-size: 13px;
}
.networktable td {
  font-size: 13px;
}
.rfdropdwon {
  width: 268px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 20px;
  opacity: 1;
  padding: 10px 25px;
}
.infoBox-wrapper {
  display: none;
}
.infoBox {
  width: 200px;
  background: #000;
  font-size: 12px;
  padding: 1em 1em;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.rm-m-b {
  margin-bottom: 0px !important;
}
.buildsearchicon {
  color: #b1bdc9;
  padding-top: 10px;
  padding-right: 0px;
  padding-left: 7px;
  font-size: 16px;
}
.infowindowclose {
  font-size: 17px;
  margin: -2px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}
.submitcheck {
  font-size: 18px;
  color: #00d56e;
  position: absolute;
}
.submitcheckmob {
  font-size: 18px;
  color: #00d56e;
  position: absolute;
  margin-top: 2px;
}
.calculatorequals {
  line-height: 44px;
  text-align: center;
  font-size: 45px;
  color: #9b9b9b;
  padding-top: 10px;
}
.calculatorselect {
  border: 1px solid #ced7df;
  border-radius: 0px 0px 6px 6px;
}
.calculatorselect.form-control {
  background: #fff url(https://ssl.gstatic.com/ui/v1/disclosure/grey-disclosure-arrow-up-down.png) right center
    no-repeat content-box;
  color: #000;
}
.calculatorinput {
  border: 1px solid #ced7df;
  width: 100%;
  height: 45px;
  border-radius: 6px 0px 0px 6px;
  padding: 10px;
  font-size: 18px;
}
.calcinlineselect {
  height: 45px !important;
  border-left: 0px !important;
  border-radius: 0px 6px 6px 0px !important;
}
.calculator input[type="number"]:hover::-webkit-inner-spin-button {
  display: none;
}
.calculator input[type="number"]:focus::-webkit-inner-spin-button {
  display: none;
}
.badge {
  line-height: 1.5;
  font-size: 11px;
}

.imagelink {
  color: #0059b2;
  font-size: 13px;
  padding-left: 10px;
  cursor: pointer;
  margin: 2px;
}
.legend {
  height: 20px;
  width: 20px;
  border-radius: 6px;
  border: none;
  opacity: 1;
}
@media (max-width: 768px) and (min-width: 479px) {
  .calcfont .form-control {
    font-size: 18px;
  }
  .calcfont input[type="text"][disabled] {
    font-size: 18px;
  }
}
@media (max-width: 479px) and (min-width: 380px) {
  .calcfont .form-control {
    font-size: 17px;
  }
  .calcfont input[type="text"][disabled] {
    font-size: 17px;
  }
}
@media (max-width: 380px) and (min-width: 350px) {
  .calcfont .form-control {
    font-size: 15px;
  }
  .calcfont input[type="text"][disabled] {
    font-size: 15px;
  }
}
@media (max-width: 350px) and (min-width: 320px) {
  .calcfont .form-control {
    font-size: 13px;
  }
  .calcfont input[type="text"][disabled] {
    font-size: 13px;
  }
}
@media (max-width: 320px) {
  .calcfont .form-control {
    font-size: 11px;
  }
  .calcfont input[type="text"][disabled] {
    font-size: 11px;
  }
}
.networkcheck .custom-control {
  padding-left: 0px;
}

.text-block {
  position: absolute;
  top: 13px;
  right: 12px;
}

.maploder {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(0px, 0px);
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 70px;
  height: 70px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.center {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.cluster-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #3c8cff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid;
  border-color: #fff;
}

.cluster-text {
  font-size: 12px !important;
  font-weight: 600;
  color: #000;
}

.outdoor-loader {
  height: 60px;
  width: 60px;

  -webkit-animation: outdoor-loader-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: outdoor-loader-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes outdoor-loader-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes outdoor-loader-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.outdoor-loader::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;

  margin: auto;
  width: 8px;
  height: 8px;
  background: #ef4962;
  border-radius: 50%;
  -webkit-animation: outdoor-loader-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: outdoor-loader-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes outdoor-loader-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(45px, 0, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes outdoor-loader-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(45px, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.outdoor-loader::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #ef4962;
  border-radius: 50%;
  -webkit-animation: outdoor-loader-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: outdoor-loader-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes outdoor-loader-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(-45px, 0, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes outdoor-loader-3 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(-45px, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.outdoor-loader span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 60px;
}
.outdoor-loader span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #ef4962;
  border-radius: 50%;
  -webkit-animation: outdoor-loader-4 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: outdoor-loader-4 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes outdoor-loader-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, 45px, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes outdoor-loader-4 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, 45px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.outdoor-loader span::after {
  content: "";
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #ef4962;
  border-radius: 50%;
  -webkit-animation: outdoor-loader-5 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: outdoor-loader-5 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes outdoor-loader-5 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, -45px, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes outdoor-loader-5 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, -45px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

ion-item {
  --highlight-color-focused: none;
}

ion-label {
  color: #9b9b9b !important;
  opacity: 1;
  font-family: Roboto, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400;
}
.item-has-focus.label-floating.sc-ion-label-md-h,
.item-has-focus .label-floating.sc-ion-label-md-h,
.item-has-focus.label-stacked.sc-ion-label-md-h,
.item-has-focus .label-stacked.sc-ion-label-md-h {
  color: #9b9b9b !important;
  opacity: 1;
  font-family: Roboto, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400;
}

.item-interactive-disabled.sc-ion-label-md-h:not(.item-multiple-inputs),
.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
  cursor: default;
  opacity: 1 !important;
  pointer-events: none;
  color: #9b9b99 !important;
}
.item-native {
  padding-left: 0.575rem !important;
}

.label-floating.sc-ion-label-md-h,
.label-stacked.sc-ion-label-md-h {
  margin-top: -5px !important;
  margin-bottom: 5px !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.floating-label {
  position: relative;
}

.floating-select {
  width: 100%;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 6px;
  opacity: 1;
  font-size: 14px;
  padding-top: 15px;
  padding-left: 14px;
  display: block;
}
.selectarrow {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position: right 21%;
  background-repeat: no-repeat;
  background-size: 12px;
  background-origin: content-box;
  background-image: url("./assets/img/checked.png");
  padding-right: 10px;
}
.floating-select:focus {
  outline: none;
}

.floating-label label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 14px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-select:focus ~ label,
.floating-select:not([value=""]):valid ~ label {
  color: #9b9b9b !important;
  left: 15px;
}

.paramSelect {
  border: 0px solid #cccccc !important;
  border-top: 0.5px solid #8f9fac !important;
  border-radius: 0px !important;
  padding-top: 19px !important;
}

.paramSelectmob {
  border: 0.5px solid #d5d4d5;
  width: 100%;
  height: 60px;
  border-radius: 0px;
}

.actionIconBtn {
  width: 0px;
  height: 0px;
}
