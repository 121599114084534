.col-wise {
  display: flex;
  flex-direction: column;
}

.background {
  background-color: #fff;
}

.update-available-img {
  max-height: 35%;
  object-fit: contain;
  object-position: center;
}

.update-text {
  font-size: 18px;
  letter-spacing: 1px;
  color: #0059b2;
  font-weight: 500;
  text-transform: none;
}

.update-subtext {
  font-size: 14px;
  letter-spacing: 1px;
  color: rgb(51, 51, 51);
  font-weight: 500;
}

.update-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  height: 48px !important;
  background: #0059b2 0 0 no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  font-size: 14px;
  letter-spacing: 0;
  color: #fff;
}

.update-button:hover {
  text-decoration: none;
  color: #fff;
}

.update-info {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #aaa;
  font-weight: 400;
}
