@media (max-width: 768px) and (min-width: 479px){
    .webonly{
        display: none;
    }
    .d-grid{
        display: grid !important;
    }
    .backicon-mob {
        position: absolute;
        padding: 18px 17px 10px 11px;
        cursor: pointer;
        width: 45px;
    }
}
@media (max-width: 479px) {
    .webonly{
        display: none;
    }
    .d-block{
        display: block !important;
    }
    .backicon-mob {
        position: absolute;
        padding: 18px 17px 10px 11px;
        cursor: pointer;
        width: 45px;
    }
}
.logo-hide {
    display: none;
}
.welcome-fixed {
    top: 80px;
    position: fixed;
}
.homecontent-bottom {
    position: fixed !important;
    bottom: 0 !important;
    padding-bottom: calc(env(safe-area-inset-bottom, 0px) + 14px) !important;
}
.homecontent-relative {
    position: relative !important;
}
.homecontent-padded {
    position: fixed !important;
    bottom: 0 !important;
    padding-bottom: calc(env(safe-area-inset-bottom, 0px) + 14px) !important;
}

.homecontent-hero {
    position: fixed !important;
    bottom: 0 !important;
    padding-bottom: calc(env(safe-area-inset-bottom, 0px) + 14px) !important;
}

@media (max-width: 2000px) and (min-width: 768px){ 
    .mobonly{
        display: none;
    }
}
